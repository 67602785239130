import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const filesAdapter = createEntityAdapter()

const initialState = filesAdapter.getInitialState()

export const filesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFiles: builder.query({
            query: () => "/file/getFileList",
            validateStatus: (response, result) => {
                console.log(response)
                return response.status === 200 && !result.isError;
            },
            transformResponse: responseData => {
                return filesAdapter.setAll(initialState, responseData);
            },
            providesTags: (result, error, arg) => {
                if(result?.ids) {
                    return [
                        { type: 'File', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'File', id}))
                    ]
                } else 
                    return [{ type: 'File', id: 'LIST'}]
            }
        }),
        uploadFile: builder.mutation({
            query: (request) => {
                let formData = new FormData();
                formData.append('userId', request.userId);
                formData.append('file', request.file);

                return {
                  url: '/file/uploadFile',
                  method: 'POST',
                  body:  formData,
                  formData: true  
                };
              }
        }),
        processFile: builder.mutation({
            query: (processFileRequest) => ({
                url: '/file/processFile/' + processFileRequest.id,
                method: 'POST',
                body: processFileRequest.userId
            })
        }),
        exportFile: builder.mutation({
            query: (exportFileRequest) => ({
                url: '/file/exportFile',
                method: 'POST',
                body: {
                    ...exportFileRequest
                },
                responseHandler: ((response) => response.blob()),
                cache: "no-cache"
            })
        }),
        updateFile: builder.mutation({
            query: ({ id, fileDetails }) => ({
                url: '/file/updateFile/' + id,
                method: 'POST',
                body: {
                    ...fileDetails
                }
            })
        }),
        createClient: builder.mutation({
            query: createClientRequest => ({
                url: '/file/client/createClient',
                method: 'POST',
                body: {
                    ...createClientRequest
                }
            })
        }),
        getFileIdByAdvisorId: builder.mutation({
            query: advisorId => ({
                url: '/file/getFileIdByAdvisorId/' + advisorId,
                method: 'GET'
            })
        }),
        getFileById: builder.mutation({
            query: (getFileRequest) => ({
                url: '/file/getFileById/' + getFileRequest.id,
                method: 'POST',
                body: getFileRequest.userId
            })
        }),
        deleteClient: builder.mutation({
            query: ({ id, deleteClientRequest }) => ({
                url: '/file/client/deleteClient/' + id,
                method: 'POST',
                body: {
                    ...deleteClientRequest
                }
            })
        }),
        deleteFile: builder.mutation({
            query: (deleteFileRequest) => ({
                url: '/file/deleteFile',
                method: 'POST',
                body: {
                    ...deleteFileRequest
                },
            })
        }),
        downloadTemplates: builder.mutation({
            query: () => ({
                url: '/file/template/downloadTemplates',
                method: 'GET',
                responseHandler: ((response) => response.blob()),
                cache: "no-cache"
            })
        }),
    })
})

export const {
    useGetFilesQuery,
    useUploadFileMutation,
    useProcessFileMutation,
    useExportFileMutation,
    useUpdateFileMutation,
    useCreateClientMutation,
    useGetFileIdByAdvisorIdMutation,
    useGetFileByIdMutation,
    useDeleteClientMutation,
    useDeleteFileMutation,
    useDownloadTemplatesMutation,
} = filesApiSlice


//returns the query result object
export const selectFilesResult = filesApiSlice.endpoints.getFiles.select()

//creates memoized selector`
const selectFilesData = createSelector(
    selectFilesResult,
    filesResult => filesResult.data //normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename with aliases using destructuring
export const {
    selectAll: selectAllFiles,
    selectById: selectFileById,
    selectIds: selectFileIds
    //Pass in a selector that returns the users slice of state
} = filesAdapter.getSelectors(state => selectFilesData(state) ?? initialState)