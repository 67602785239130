import { useState } from "react"
import SearchableDropdown from "../../../../components/SearchableDropdown"
import { useSelector } from "react-redux"
import { selectCurrentRole } from "../../../auth/authSlice"

const FileDetailsControls = ({ handleSelectedClientChange, clientOptions, selectedClient, setCreateClientAccountModalOpen, handleCopyHouseHold, fileName, handleExportFile, isExportFileLoading }) => {

    const role = useSelector(selectCurrentRole)
    const [copyHouseHoldClient, setCopyHouseHoldClient] = useState(null)

    const onSelectedClientChange = (client) => {
        if(!client) {
            return
        }
        handleSelectedClientChange(client)
    }

    return (
        <div className={`file-details-controls ${role !== "EMPLOYEE" || 'employee'}`}>
            <div className="control">
                <h3 className="header">Client</h3>
                <div className="control-main">
                    <div className="search-dropdown">
                        <SearchableDropdown
                            options={clientOptions}
                            label="name"
                            id="id"
                            selectedVal={selectedClient ? selectedClient.firstName + " " + selectedClient.lastName : null}
                            placeholderVal={"Select Client..."}
                            handleChange={(val, id) => onSelectedClientChange(id)}
                        />
                    </div>
                    <div className="control-button">
                        <button onClick={() => setCreateClientAccountModalOpen(true)}>Create Account</button>
                    </div>
                </div>
            </div>
            <div className="control">
                <h3 className="header">Copy Household</h3>
                <div className="control-main">
                    <div className="search-dropdown">
                        <SearchableDropdown
                            options={clientOptions}
                            label="name"
                            id="id"
                            selectedVal={copyHouseHoldClient ? copyHouseHoldClient.clientDetails.firstName + " " + copyHouseHoldClient.clientDetails.lastName : null}
                            placeholderVal={"Select Client..."}
                            handleChange={(val, id) => setCopyHouseHoldClient(id)}
                        />
                    </div>
                    <div className="control-button">
                        <button disabled={!(selectedClient && copyHouseHoldClient)} onClick={() => handleCopyHouseHold(copyHouseHoldClient)}>Copy</button>
                    </div>
                </div>
            </div>
            {role === "EMPLOYEE" &&
                <div className="control">
                    <h3 className="header">File</h3>
                    {fileName && 
                        <div className="control-main">
                            <div><label>{fileName}</label></div>
                            <div className="control-button">
                                <button disabled={isExportFileLoading} onClick={() => handleExportFile()}>Export</button>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default FileDetailsControls