import { combineReducers } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'
import componentReducer from '../components/componentSlice'
import authReducer from '../features/auth/authSlice'
import fileReducer from '../features/file/fileSlice'
import supportStaffReducer from '../features/file/support/supportStaffSlice'
import reportReducer from '../features/report/reportSlice'
import formReducer from '../features/form/formSlice'

// Action type to reset the store
const RESET_STATE = 'RESET_STATE'

// Combine all your reducers
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  component: componentReducer,
  auth: authReducer,
  file: fileReducer,
  supportStaff: supportStaffReducer,
  report: reportReducer,
  form: formReducer,
})

// Root reducer with reset functionality
const rootReducerWithReset = (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined  // Reset the entire state to undefined
  }
  return rootReducer(state, action)
}

export { rootReducerWithReset, RESET_STATE }