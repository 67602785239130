import "./Forms.css"

import SearchableDropdown from "../../components/SearchableDropdown"
import { useGetClientsAndBundlesMutation, useGenerateFormsMutation, useGetFormsByUserIdMutation } from "./formApiSlice"
import { useEffect, useState } from "react"
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentId } from "../auth/authSlice"
import { useNavigate } from "react-router-dom"
import { Dropdown, Space } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { selectFormsList, setFormsList } from "./formSlice"
import { selectFile } from "../file/fileSlice"

const Forms = () => {
    const userId = useSelector(selectCurrentId)
    const formsList = useSelector(selectFormsList)
    const file = useSelector(selectFile)
    
    const navigate = useNavigate()
    const [userForms, setUserForms] = useState([])
    const [selectedBundle, setSelectedBundle] = useState(null)
    const [selectedClient, setSelectedClient] = useState(null)
    const [bundles, setBundles] = useState([])
    const [clients, setClients] = useState([])
    const [errorMessage, setErrorMessage] = useState(null)

    const [getClientsAndBundles, { isLoading }] = useGetClientsAndBundlesMutation()
    const [generateForms, {isLoading: isGenerateFormsLoading}] = useGenerateFormsMutation()
    const [getFormsByUserId, {isLoading: isGetFormsByUserIdLoading}] = useGetFormsByUserIdMutation()

    const dispatch = useDispatch()

    useEffect(() => {
        if(isGenerateFormsLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isGenerateFormsLoading])

    useEffect(() => {
        if(isGetFormsByUserIdLoading && !formsList) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isGetFormsByUserIdLoading])
    
    useEffect(() => {
        async function handleGetClientAndBundles() {
            try {
                const response = await getClientsAndBundles({ userId : userId, fileDetailsId: file }).unwrap()
                setBundles(response?.bundles)
                setClients(response?.clients)
            } catch(err) {
                if(!err.status || err.status === "FETCH_ERROR") {
                    setErrorMessage("No Server Response")
                } else {
                    setErrorMessage(err.data?.detail)
                }
            }
        }
        if(userId && file) {
            handleGetClientAndBundles()
        }
    }, [userId])

    useEffect(() => {
        async function handleGetFormsByUserId() {
            try {
                const response = await getFormsByUserId(userId).unwrap()
                if(response) {
                    dispatch(setFormsList(response))
                    setUserForms(response)
                }
            } catch(err) {
                if(!err.status || err.status === "FETCH_ERROR") {
                    setErrorMessage("No Server Response")
                } else {
                    setErrorMessage(err.data?.detail)
                }
            }
        }
        if(userId) {
            handleGetFormsByUserId()
        }
    }, [userId])

    useEffect(() => {
        if(formsList) {
            setUserForms(formsList)
        } 
    }, [formsList])

    const getBundleOptions = () => {
        return bundles.map(bundle => ({id: bundle, name: bundle.name}))
    }

    const getClientOptions = () => {
        return clients.map(client => ({id: client, name: client.clientDetails.firstName + " " + client.clientDetails.lastName}))
    }

    const handleGenerateForms = async () => {
        setErrorMessage("")

        if(!selectedClient || !selectedBundle) {
            return
        }

        try {
            let response = await generateForms({
                userId,
                fileDetailsId: file,
                clientDetailsAndRows: selectedClient,
                bundle: selectedBundle.id
            }).unwrap()

            if(response) {
                dispatch(setFormsList([...formsList, response]))
                navigate(`clientForms/${response.id}`)
            }
        } catch(err) {
            if(!err.status || err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response")
            } else {
                setErrorMessage(err.data?.detail)
            }
        }
    }

    const getUserFormsTable = () => {
        return (
            <div className="file-table-container">
                <div className="file-table-header">
                    <header>Client Forms</header>
                    <div className='file-table-header-field'>
                    </div>
                </div>
                {
                    userForms && userForms.length > 0 ?
                        <table className="file-table">
                            <tr>
                                <th></th>
                                <th>Client Name</th>
                                <th>Social Security Number</th>
                                <th>Form Name</th>
                                <th>Form Id</th>
                                <th>Last Updated</th>
                            </tr>
                            {mapTableRows(userForms)}
                        </table>
                        :
                        <table className="file-table">
                            <tr>
                                <td>No forms created yet...</td>
                            </tr>
                        </table>
                }
            </div>   
        )
    }

    const mapTableRows = (forms) => {
        let rows = []
        forms.forEach(form => {
            form.formDetails.forEach(details => {
                rows = [...rows,
                    <tr>
                        <td>{createFillButton(form.id, details.formId)}</td>
                        <td>{`${form.client.firstName || ''} ${form.client.lastName || ''}`}</td>
                        <td>{form.client.socialSecurityNumber}</td>
                        <td>{details.formName}</td>
                        <td>{details.formId}</td>
                        <td>{details.lastUpdated}</td>
                    </tr>
                ]
            })
        })

        return rows
    }

    const createFillButton = (id, formId) => {
            let items = [
                {
                    key: 1,
                    label: 'Fill Form',
                }
            ]
            return (
                <Dropdown
                    menu={{
                    items,
                    onClick: () => navigate(`clientForms/${id}?form=${formId}`)
                    }}
                >
                    <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        <DownOutlined />
                    </Space>
                    </a>
                </Dropdown>
            )
        }

    return(
        <div className="table-page-container">
            {errorMessage && 
                <div className="support-error">
                    <p>{errorMessage}</p>
                </div>
            }
            <div className="forms-header">
                <div className='top-header'>
                    <header>Forms</header>
                </div>
            </div>
            <div className="generate-forms">
                <div className="search-dropdown">
                    <SearchableDropdown
                        options={getClientOptions()}
                        label="name"
                        id="id"
                        selectedVal={selectedClient ? selectedClient.clientDetails.firstName + " " + selectedClient.clientDetails.lastName : null}
                        placeholderVal={"Select Client..."}
                        handleChange={(val, id) => setSelectedClient(id)}
                    />
                </div>
                <div className="search-dropdown">
                    <SearchableDropdown
                        options={getBundleOptions()}
                        label="name"
                        id="id"
                        selectedVal={selectedBundle?.name}
                        placeholderVal={"Select Form Bundle..."}
                        handleChange={(val, id) => setSelectedBundle(id)}
                    />
                </div>
                <div className='generate-forms-field'>                    
                    <button onClick={() => handleGenerateForms()}>Generate Forms</button>
                </div>
            </div>
            {getUserFormsTable()}
        </div>
    )
}

export default Forms