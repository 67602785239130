import { useRef, useState, useEffect } from "react"
import "./FileUpload.css"
import { useUploadFileMutation, useDownloadTemplatesMutation } from "./fileApiSlice"
import { useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import { useGetAdvisorUsersMutation } from "../user/userApiSlice"
import { downloadFile } from "../utils/Utilities"

const FileUpload = () => {
    const [ uploadFile,  {
        isLoading,
        isSuccess,
    }] = useUploadFileMutation()

    const [ downloadTemplates,  {
        isLoading: isDownloadTemplateLoading,
    }] = useDownloadTemplatesMutation()

    const [ getAdvisorUsers,  {
    }] = useGetAdvisorUsersMutation()

    const navigate = useNavigate()

    const [file, setFile] = useState(null)
    const [selectedAdvisor, setSelectedAdvisor] = useState(null)
    const [advisorList, setAdvisorList] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const selectFileRef = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if(isSuccess) {
            setFile(null)
            navigate('/dash/fileList')
        }
    }, [isSuccess, navigate])

    useEffect(() => {
        async function getAdvisors() {
            try {
                const response = await getAdvisorUsers().unwrap()
                if(response) {
                    setAdvisorList(response)
                }
            } catch(err) {
                if(!err.status || err.status === "FETCH_ERROR") {
                    setErrorMessage("No Server Response")
                } else {
                    setErrorMessage(err.data?.detail)
                }
            }
        }
        getAdvisors()
      }, [])

    useEffect(() => {
        if(isLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading])

    const addFile = (event) => {
        setErrorMessage("")
        if(event.target.files && event.target.files.length > 0){
            const chosenFile = event.target.files[0];
            const fileName = chosenFile.name || '';
            const fileExt = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
            console.log(fileExt)
            if(['xlsx'].indexOf(fileExt.toLowerCase()) === -1) {
                setErrorMessage("Only xlsx is allowed")
            } else {
                setFile(chosenFile)
            }
        }
    }

    const deleteFile = () => {
        setFile(null)
    }

    const openSelectFile = () => {
        selectFileRef.current.click();
    }

    const onUploadFile = async () => {
        setErrorMessage("")
        if(!file) {
            setErrorMessage("No file selected")
            return
        }
        if(!selectedAdvisor) {
            setErrorMessage("No advisor selected")
            return
        }

        try {
            await uploadFile({userId: selectedAdvisor, file}).unwrap()
        } catch(err) {
            if(!err.status || err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response")
            } else {
                setErrorMessage(err.data?.detail)
            }
        }
    }

    const handleDownloadTemplates = async () => {
        setErrorMessage("")
        if(isDownloadTemplateLoading) {
            return
        }
        try {
            const data = await downloadTemplates().unwrap()
            console.log(data)

            downloadFile(data, "Templates.zip")
        } catch(err) {
            if(err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response");
            } else if(err.status === 400) {
                setErrorMessage("Error exporting file");
            } else if(err.status === 401) {
                setErrorMessage("Unauthorized");
            } else {
                setErrorMessage(err.data?.message);
            }
        }
    }


    return (
        <div className="main">
            <div className="file-process-container">
                {errorMessage && 
                    <div>
                        <p>Error in file upload: {errorMessage}</p>
                    </div>
                }
                <div className='top-header'>
                    <header>Upload File</header>
                </div>
                <div>
                    <div className="advisor-select">
                        <label>Advisor</label>
                        <select value={selectedAdvisor} onChange={(e) => setSelectedAdvisor(e.target.value)}>
                            <option disabled selected value> -- select an option -- </option>
                            {advisorList?.map(advisor => <option value={advisor.id}>{advisor.username}</option>)}
                        </select>
                    </div>
                    {file ?
                        <div>
                            <div className="inputBox">
                                <label>{file.name}</label>
                                <button className="delete" onClick={deleteFile}>Delete file</button>
                            </div>
                            <div>
                                <button className="process" onClick={onUploadFile}>Upload</button>
                            </div>
                        </div>
                        :
                        <div>
                            <input style={{visibility: "hidden"}} type="file" ref={selectFileRef} onChange={addFile} />
                            <button className="process" onClick={openSelectFile}>Select File</button>
                        </div>
                    }
                    <div className="inputBox">
                        <a className="hyperlink" role="button" onClick={handleDownloadTemplates}>Download Templates</a> 
                    </div>
                </div>       
            </div>
        </div>
    )    
}

export default FileUpload