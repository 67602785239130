import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { rootReducerWithReset } from './reducers'

export const store = configureStore({
  reducer: rootReducerWithReset,  // Use the modified root reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware),
  devTools: true,
})

setupListeners(store.dispatch)