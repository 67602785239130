import { apiSlice } from "../../../../app/api/apiSlice"

export const formDetailsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFileDetails: builder.mutation({
            query: (request) => ({
                url: `/file/details/${request.userId}/getFileDetails/${request.fileDetailsId}`,
                method: 'GET'
            })
        }),
        getFileClientAndAccountsInfo: builder.mutation({
            query: (request) => ({
                url: `/file/details/getFileClientAndAccountsInfo`,
                method: 'POST',
                body: {
                    ...request
                }
            })
        }),
        updateFileClientAndAccountsInfo: builder.mutation({
            query: (request) => ({
                url: `/file/details/updateFileClientAndAccountsInfo`,
                method: 'POST',
                body: {
                    ...request
                }
            })
        }),
        deleteClientAccount: builder.mutation({
            query: (request) => ({
                url: `/file/details/deleteClientAccount`,
                method: 'POST',
                body: {
                    ...request
                }
            })
        }),
        createClientAccount: builder.mutation({
            query: (request) => ({
                url: `/file/details/createClientAccount`,
                method: 'POST',
                body: {
                    ...request
                }
            })
        }),
    })
})

export const { 
    useGetFileDetailsMutation,
    useGetFileClientAndAccountsInfoMutation,
    useUpdateFileClientAndAccountsInfoMutation,
    useDeleteClientAccountMutation,
    useCreateClientAccountMutation
} = formDetailsApiSlice