import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isUserPasswordTemp, selectCurrentUsername, selectCurrentRole, selectCurrentId } from "../auth/authSlice"
import { useSelector, useDispatch } from "react-redux"
import { useUpdatePasswordMutation } from './userApiSlice'
import { useGetFileIdByAdvisorIdMutation } from '../file/fileApiSlice'
import { setPasswordTemp } from '../auth/authSlice'
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import "./PasswordChange.css"
import PasswordValidator from './PasswordValidator'
import { isValidPassword } from './utils/PasswordUtil'
import { message } from 'antd'

const PasswordChange = () => {
    const id = useSelector(selectCurrentId)
    const username = useSelector(selectCurrentUsername)
    const role = useSelector(selectCurrentRole)
    const isPasswordTemp = useSelector(isUserPasswordTemp)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [messageApi, contextHolder] = message.useMessage()

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [ updatePassword,  {
        isLoading,
    }] = useUpdatePasswordMutation()
    
    const [ getFileIdByAdvisorId,  {
        isLoading: isGetFileLoading,
    }] = useGetFileIdByAdvisorIdMutation()

    const alertMessage = (message) => {
        messageApi.success(message);
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setErrorMessage("")

        if(!password) {
            setErrorMessage("Password is required.")
            return
        }

        if(!isValidPassword(password)) {
            return
        }

        if(password !== confirmPassword) {
            setErrorMessage("Password does not match")
            return
        }
        
        try {
            await updatePassword({password, id})
            dispatch(setPasswordTemp(false))
            setPassword("")
            setConfirmPassword("")
            alertMessage("Password updated")
            navigateAfterPasswordChange()
        } catch(err) {
            if(!err.status) {
                setErrorMessage("No Server Response");
            } else if(err.status === 400 || err.status === 401) {
                setErrorMessage("Incorrect Username or Password")
            } else {
                setErrorMessage(err.data?.message)
            }
        }
    }

    const navigateAfterPasswordChange = async () => {
        if(role === "ADVISOR") {
            try {
                const file = await getFileIdByAdvisorId(id).unwrap()
                console.log(file)
                if(file) {
                    navigate(`/dash/fileDetails/${file.id}`)
                } else {
                    navigate(`/dash/fileDetails/${0}`)
                }
            } catch (err) {
                if(err.status == 403) {
                    navigate("/dash/error403")
                } else {
                    navigate(`/dash/fileDetails/${0}`)
                }
            }
        }
        else if (role === "SUPPORT_STAFF") {
            navigate("/dash/support")
        }
        else {
            navigate("/dash")
        }
    }

    useEffect(() => {
        if(!isPasswordTemp) {
            navigate("/dash")
        }
    }, [])

    useEffect(() => {
        if(isLoading || isGetFileLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading || isGetFileLoading])

    return(
        <div className="password-change-main">
            {contextHolder}
            <div className="password-change-container">
                <div className="password-change">
                    <div className='password-change-top-header'>
                        <header>Welcome, {username}!</header>
                        <h3>Please update your password before proceeding.</h3>

                        <PasswordValidator password={password} />
                    </div>
                    {errorMessage && 
                        <div className="errorMessage">
                            <p>{errorMessage}</p>
                        </div>
                    }
                    <form onSubmit={(e) => onSubmit(e)}>
                        <div className='input-field'>
                            <input required className='input' type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className='input-field'>
                            <input required className='input' type='password' placeholder='Confirm Password' onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div>
                        <div className='input-field'>
                            <button type="submit">Update Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PasswordChange